export const SIGN_UP_REQUEST_PROCESSING = "SIGN_UP_REQUEST_PROCESSING"
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS"
export const SIGN_UP_REQUEST_FAILED = "SIGN_UP_REQUEST_FAILED"

export const SIGN_IN_REQUEST_PROCESSING = "SIGN_IN_REQUEST_PROCESSING"
export const SIGN_IN_REQUEST_SUCCESS = "SIGN_IN_REQUEST_SUCCESS"
export const SIGN_IN_REQUEST_FAILED = "SIGN_IN_REQUEST_FAILED"

export const UPDATE_USER_DATA_REQUEST_PROCESSING = "UPDATE_USER_DATA_REQUEST_PROCESSING"
export const UPDATE_USER_DATA_REQUEST_SUCCESS = "UPDATE_USER_DATA_REQUEST_SUCCESS"
export const UPDATE_USER_DATA_REQUEST_FAILED = "UPDATE_USER_DATA_REQUEST_FAILED"

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"